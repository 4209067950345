.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgb(255, 254, 242);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

body {
  padding-top: 80px; /* Adjust the padding-top value to match the height of your navbar */
}

.mobile-menu {
  display: none;
}
.ant-drawer .ant-menu-light .ant-menu-item-selected,
.ant-drawer .ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: #25424c;
  color: #fffef2;
}

@media (max-width: 768px) {
  .menu {
    display: none !important;
  }
  .mobile-menu {
    display: flex;
    margin: 10px;
    margin-top: 20px;
  }
  .ant-drawer-content {
    background-color: #fffef2 !important;
  }
  .ant-menu {
    background-color: #fffef2 !important;
  }
}
