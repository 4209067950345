.background-container::before {
    content: '';
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: -4px;
    background-color: rgba(37, 66, 76, 0.8); /* Black overlay with 50% opacity */
    z-index: 1;
}

.react-tel-input{
    display: inline-block;
    width: 60% !important;
    max-width: 500px;
}